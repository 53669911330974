import "./Footer.css";

const handleButtonClick = () => {
  // Logic to open cookie settings - OneTrust API
  if (window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay();
  } else {
    console.error("OneTrust is not defined");
  }
};
const Footer = () => {
  return (
    <footer>
      <div className="content-body-footer">
        <p className="footer-description">2024 Copyright | Deloitte</p>
        <p className="footer-cookie-link">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://cookienotice.deloitte.com"
          >
            Cookies
          </a>
        </p>
        <button className="footer-cookie-setting-link" onClick={handleButtonClick}>
          Cookie Settings
        </button>
      </div>
    </footer>
  );
};

export default Footer;

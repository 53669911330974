import { loginRequest, graphConfig, msalConfig } from "../authConfig";
import axios from "axios";
import { CacheLookupPolicy } from '@azure/msal-browser';
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param msalInstance 
 */
export async function getMSGraphProfile(msalInstance) {
    try {
        const tkResponse = await getAccessToken(msalInstance);
        const bearer = `Bearer ${tkResponse.accessToken}`;

        const options = {
            headers: { 
                "Authorization" : bearer,
                "content-type": "application/json"
             }
        };
    
        return axios.get(graphConfig.graphMeEndpoint, options)
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * Fetches an access token to a MS Graph API call. Returns user profile photo
 * @param msalInstance 
 */
export async function getMSGraphPhoto(msalInstance) {
    try {
        const tkResponse = await getAccessToken(msalInstance);
        const bearer = `Bearer ${tkResponse.accessToken}`;
        
        const options = {
            headers: { "Authorization" : bearer },
            responseType: "arraybuffer",
            cache: true
        };
        return axios.get(graphConfig.graphMeEndpoint + '/photos/48x48/$value', options);
    } catch (error) {
        return Promise.reject(error)
    }
}


async function getAccessToken(msalInstance) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        msalInstance.clearCache();
        msalInstance.logoutRedirect({postLogoutRedirectUri: msalConfig.auth.redirectUri});
        return Promise.reject(Error('No active account'))
    }
    return msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
        cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken
    })
}
import { useState } from "react";
import {
  Button,
  Col,
  Container,
  Collapse,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import imgInfo from "../../assets/images/img-info-request-access-modal.svg";
import imgTreeIcon from "../../assets/images/tree-icon.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imgInfoBackground from "../../assets/images/img-info-bg-request-access-modal.svg";
import styles from "./AddCapabilityModal.module.css";
import "../../assets/styles/theme.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ImgCloud from "../common/ImgCloud/ImgCloud";
import CapabilityTreeNodeButton from "../CapabilityTreeNodeButton/CapabilityTreeNodeButton";
import CapabilityDocumentAccordion from "../CapabilityDocumentAccordion/CapabilityDocumentAccordion";
import CapabilityAPI from "../../apis/CapabilityAPI";

const AddCapabilityModal = ({
  isAddCapabilityModalShown,
  handleClose,
  capabilityLevel,
  parentCapability,
  documents,
  formAddNewCapability,
  handleSetFormAddNewCapability,
  selectedIndustry,
  selectedSector,
  selectedSubSector,
  capabilityTree,
}) => {
  const [isMappingShown, setIsMappingShown] = useState(true);
  const [isDocumentsShow, setIsDocumentsShown] = useState(false);
  const [documentData, setDocumentData] = useState(documents);
  const [capabilityJSON, setCapabilityJSON] = useState();
  const [isFormValid, setFormValid] = useState(false);

  // Determine the child capability level from the selected parent level

  const childCapabilityLevel = capabilityLevel.replace(
    /(\d+)/,
    (match, number) => {
      return String(Number(number) + 1);
    }
  );

  // Function to Add a New Node to the existing JSON

  function insertChild(obj, parentTitle, childObj) {
    if (obj.title === parentTitle) {
      if (!obj.children) {
        obj.children = [];
      }
      obj.children.push(childObj);
      setCapabilityJSON(obj);
      return;
    }
    if (obj.children) {
      for (const child of obj.children) {
        insertChild(child, parentTitle, childObj);
      }
    }
    return false;
  }

  const submitCapabilityDetails = (event) => {
    event.preventDefault();

    const newNode = {
      level: childCapabilityLevel,
      title: formAddNewCapability.title,
      parent: parentCapability,
      description: formAddNewCapability.description,
      children: [],
    };

    insertChild(capabilityTree[0], parentCapability, newNode);
    console.log(capabilityTree[0], "test");
    const formData = {
      industry: selectedIndustry,
      sector: selectedSector,
      subsector: selectedSubSector,
      model: JSON.stringify(capabilityTree),
    };

    CapabilityAPI.addCapabilityDetailsAPI(formData)
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          handleClose();
        }
      })

      .catch((err) => {
        console.log(err);
      });

    // Perform the form validation
    const isTitleValid = formAddNewCapability.title.trim() !== "";
    const isDescriptionValid = formAddNewCapability.description.trim() !== "";
    const isFormValid = isTitleValid && isDescriptionValid;

    // Update the form validity state
    setFormValid(isFormValid);

    // Handle form submission if the form is valid
    if (isFormValid) {
      // Perform the form submission logic
      // e.g., call an API or update state
      // reset the form after successful submission
      resetForm();
    }

    handleSetFormAddNewCapability({
      title: "",
      level: "",
      parent: "",
      description: "",
    });
  };

  const resetForm = () => {
    // Reset the form state
    handleSetFormAddNewCapability({
      title: "",
      description: "",
    });

    // Reset the form validity state
    setFormValid(false);
  };

  const onHandleNewCapabilityInputChange = (event) => {
    handleSetFormAddNewCapability({
      ...formAddNewCapability,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Modal show={isAddCapabilityModalShown} onHide={handleClose}>
      <Modal.Title>
        <Container
          className={`d-flex justify-content-center ${styles.containerImgModalTitle}`}
        >
          <img
            src={imgInfoBackground}
            className={styles.imgInfoBgRequestAccessModal}
            alt=""
          />
          <img
            src={imgInfo}
            className={styles.imgInfoRequestAccessModal}
            alt=""
          />
        </Container>
        <Container
          className={`d-flex justify-content-center ${styles.containerTextModalTitle}`}
        >
          <h3>Add {childCapabilityLevel} capability</h3>
        </Container>
      </Modal.Title>
      <Modal.Body>
        <Collapse in={isMappingShown}>
          <Form
            onSubmit={submitCapabilityDetails}
            id="submitCapabilityDetailsForm"
          >
            <span className="d-flex">
              <img
                src={imgTreeIcon}
                alt="mapping icon"
                width="15px"
                height="15px"
                className={styles.treeIcon}
              />
              <h2 className="h2 color-primary">Mapping</h2>
            </span>
            <Row>
              <Col>
                <p className="form-label">Parent Capability Name</p>
                <CapabilityTreeNodeButton title={parentCapability} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="addCapabilityForm.capabilityName"
                >
                  <Form.Label>New capability name*</Form.Label>
                  <Form.Control
                    type="text"
                    id="title"
                    name="title"
                    value={formAddNewCapability.title}
                    onChange={onHandleNewCapabilityInputChange}
                    // isInvalid={formAddNewCapability.title.trim() === ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a name for Capability
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>New capability description*</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  id="description"
                  name="description"
                  value={formAddNewCapability.description}
                  onChange={onHandleNewCapabilityInputChange}
                  // isInvalid={formAddNewCapability.description.trim() === ""}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a description for Capability
                </Form.Control.Feedback>
              </Col>
            </Row>
            <hr />
          </Form>
        </Collapse>
        {/* <Row>
          <Col>
            <h2
              className="h2 color-primary d-flex justify-content-between"
              onClick={() => {
                setIsMappingShown(!isMappingShown);
                setIsDocumentsShown(!isDocumentsShow);
              }}
            >
              <span>
                <FontAwesomeIcon icon={faFile} /> Documents
              </span>
              <span>
                <FontAwesomeIcon
                  icon={isMappingShown ? faChevronDown : faChevronUp}
                  color="#000000"
                />
              </span>
            </h2>
          </Col>
        </Row> */}
        {isDocumentsShow && (
          <>
            <Row>
              <Form.Group
                className="mb-3 position-relative"
                controlId="addCapabilityForm.documentName"
              >
                <Form.Label>Search and select document</Form.Label>
                <Form.Control
                  id="documentName"
                  name="documentName"
                  className={styles.searchInput}
                />
                <button className={styles.searchButton}>
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </Form.Group>
            </Row>
            {documentData.length > 0 ? (
              <CapabilityDocumentAccordion documentData={documentData} />
            ) : (
              <Row className="d-flex justify-content-center p-5">
                <ImgCloud />
                <p className="text-center mt-3">
                  You have not tagged any document yet
                </p>
                <p className="form-label text-center">
                  You can search and select the document from the populated list
                  to show the documents here
                </p>
              </Row>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          variant="outline-success"
          className="ml-5"
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          variant="success"
          type="submit"
          form="submitCapabilityDetailsForm"
        >
          Add Capability
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCapabilityModal;

import styles from "./Home.module.css";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import imgUCMG from "../../assets/images/BAC Multicultural People set_Hands circle_lo 1.svg";
import imgRoleInfo from "../../assets/images/BAC Multicultural People set_Multicultural circle_lo 1.svg";
import imgRequestAccess from "../../assets/images/DEL Illustration Brand Positioning 11_lo 1.svg";
import imgInfo from "../../assets/images/img-info-request-access-modal.svg";
import imgInfoBackground from "../../assets/images/img-info-bg-request-access-modal.svg";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

import Navbar from "../Navbar/Navbar";
const apiUrl = process.env.REACT_APP_API_URL;

const Home = () => {
  const [disableButton, setDisabledButton] = useState(true);
  const { instance } = useMsal();
  const accountInfo = instance.getActiveAccount();

  const handleInputReasonChange = (event) => {
    if (event.target.value.length >= 6) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }

    setFormElevationRequestsData({
      ...formElevationRequestsData,
      [event.target.name]: event.target.value,
    });
  };

  const [elevationRequests, setElevationRequests] = useState([]);

  const [formElevationRequestsData, setFormElevationRequestsData] = useState({
    name: accountInfo ? accountInfo.name : "User",
    email: accountInfo ? accountInfo.username : "user@deloitte.com",
    accessLevel: "Authenticated",
    accessBy: accountInfo ? accountInfo.username : "user@deloitte.com",
    reason: "",
  });

  const getInitialElevationReq = () => {
    return {
      name: accountInfo ? accountInfo.name : "User",
      email: accountInfo ? accountInfo.username : "user@deloitte.com",
      accessLevel: "Authenticated",
      accessBy: accountInfo ? accountInfo.username : "user@deloitte.com",
      reason: "",
    };
  };

  const { name, email, accessLevel, accessBy, reason } =
    formElevationRequestsData;

  //Getting Type of User
  const [isAdminAPI, setIsAdminAPI] = useState(false);

  const [isAdminOrApprovedAPI, setIsAdminOrApprovedAPI] = useState(false);

  const [showElevatedAccessModal, setShowElevatedAccessModal] = useState(false);
  const [showAlreadyAuthenticatedModal, setShowAlreadyAuthenticatedModal] =
    useState(false);

  // const { isAdmin, setIsAdmin, isAdminOrApproved, setIsAdminOrApproved } =
  //   useContext(AppContext);

  useEffect(() => {
    // setIsAdmin(isAdminAPI);
    // setIsAdminOrApproved(isAdminOrApprovedAPI);
    Cookies.set("isAdmin", isAdminAPI);
    Cookies.set("isAdminOrApproved", isAdminOrApprovedAPI);
  }, [isAdminAPI, isAdminOrApprovedAPI]);

  const closeElevatedAccessModalHandler = () =>
    setShowElevatedAccessModal(false);
  const showElevatedAccessModalHandler = () => setShowElevatedAccessModal(true);

  const closeAlreadyAuthenticatedModalHandler = () =>
    setShowAlreadyAuthenticatedModal(false);
  const showAlreadyAuthenticatedModalHandler = () =>
    setShowAlreadyAuthenticatedModal(true);

  const compareUserType = () => {
    if (isAdminOrApprovedAPI === true && isAdminAPI === false) {
      showAlreadyAuthenticatedModalHandler();
    } else if (isAdminOrApprovedAPI === true && isAdminAPI === true) {
      showAlreadyAuthenticatedModalHandler();
    } else {
      showElevatedAccessModalHandler();
    }
  };
  // Contact Form to validate

  const [validated, setValidated] = useState(false);

  const submitRequestElevatedAccessForm = (event) => {
    event.preventDefault();
    // event.stopPropagation();

    // setValidated(true);

    const options = {
      headers: { "content-type": "text/plain" },
    };

    const formData = {
      name: name,
      email: email,
      accessLevel: accessLevel,
      accessBy: accessBy,
      reason: reason,
    };

    axios
      .post(apiUrl + "/user_manager", formData, options)
      .then((response) => {
        setElevationRequests([...elevationRequests, response.data]);
        setFormElevationRequestsData(getInitialElevationReq());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [selectedRadioButtonOption, setSelectedRadioButtonOption] =
    useState("option1");

  const handleRadioButtonOptionChange = (e) => {
    setSelectedRadioButtonOption(e.target.value);
  };

  //call UserType
  const params = {
    logged_in_user: accountInfo ? accountInfo.username : "",
    // logged_in_user: "user@deloitte.com",
  };

  useEffect(() => {
    axios
      .get(apiUrl + "/user_manager/logged_in_user", { params })
      .then(function (response) {
        setIsAdminAPI(response.data[0].is_admin);
        setIsAdminOrApprovedAPI(response.data[0].admin_or_authenticated);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [params]);

  return (
    <div>
      <ToastContainer />

      <div>
        <Navbar isAdmin={isAdminAPI} />
      </div>
      {/* <NavbarPublic /> */}
      {/* UCMG Info */}
      <div className={styles.contentBodyUCMGInfo}>
        <Container className="m-2 p-4">
          <Row>
            <Col>
              <div className="text-start">
                <h6 className={styles.UCMGSubTitle}>
                  Telco-as-an-Accelerator (TaaA)
                </h6>
                <h3 className={styles.UCMGTitle}>CapMap</h3>
                <p className={`${styles.UCMGDescription} mt-3`}>
                  Build and manage reusable set of accelerators, based on core
                  lead-to-cash telco competencies and leverage to grow revenue
                  and drive <br />
                  delivery excellence at TMT clients and for other adjacent
                  industry opportunities.
                </p>
              </div>
            </Col>

            <Col className="col-lg-2">
              <img
                src={imgUCMG}
                className={styles.imgUCMG}
                alt="Multicultural people set hands together"
              />
            </Col>
          </Row>

          <div className="text-start">
            <Button
              variant="success"
              className={`${styles.btnCustomSuccess} ${styles.btnAccessCapabilities} mt-1`}
              as={Link}
              to="/capabilities"
            >
              {/* Access capabilities */}
              <Link to="/capabilities" className={styles.btnLink}>
                Access capabilities
              </Link>
            </Button>
          </div>
        </Container>
      </div>

      {/* Vision */}
      {/* <div className={styles.contentBodyVision}>
        <Container className="mt-5">
          <Row>
            <div className="text-center">
              <h3 className={styles.visionTitle}>Our vision</h3>
              <p className={`${styles.visionDescription1} mt-4`}>
                We will disproportionately invest in differentiated technology
                assets to elevate the client experience
                <br />
                Technology assets are a critical component of our long-term
                growth and will power the next generation of insights and value
                for our offerings by
              </p>
              <p className={styles.visionDescription2}>
                Increasing our win rate | Accelerating time-to-value | Expanding
                our existing services
              </p>
              <hr />
            </div>
          </Row>
        </Container>
      </div> */}

      {/* Role Info */}
      <div className={styles.contentBodyRoleInfo}>
        <Container className="m-2 p-4">
          <Row>
            <Col className="col-lg-1">
              <img
                src={imgRoleInfo}
                className={styles.imgRoleInfo}
                alt="Multicultural people set hands together"
              />
            </Col>
            <Col className={`mt-5 ${styles.roleInfoColumnContent}`}>
              <div>
                <h3 className={styles.roleInfoTitle}>
                  Content is accessible for role-based users
                </h3>
                <h6 className={`${styles.roleInfoSubTitle} mt-4`}>
                  Deloitte SSO role
                </h6>
                <p className={`${styles.roleInfoDescription} mt-2`}>
                  These users will have read only access to the entry point page
                  for Deloitte SSO users with an ability to "request Elevated
                  access". Users shall be able to access L0-L2 and cannot view
                  L3. Along with this, users are not able to export any
                  capability data part of the model.
                </p>

                <h6 className={`${styles.roleInfoSubTitle} mt-4`}>
                  Authenticated role
                </h6>
                <p className={`${styles.roleInfoDescription} mt-2`}>
                  These users have all permissions and access as Deloitte SSO
                  role with the addition of rights related to view all levels of
                  capabilities, print/export any capability along with their
                  metadata and journeys. Users can view accounts details and
                  assets details also.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Request Elevated Access */}
      <div className={styles.contentBodyRequestAccess}>
        <Container className="d-flex justify-content-center mt-4">
          <Container
            className={`${styles.containerCircle} d-flex justify-content-center`}
          >
            <img
              src={imgRequestAccess}
              className={`${styles.imgRequestAccess} mt-4`}
              alt="DEL Illustration Brand Positioning"
            />
          </Container>
          <Container className={styles.containerBox}>
            <div className={`text-center ${styles.requestAccessTextContent}`}>
              <p className={styles.requestAccessDescription}>
                For existing users who want to access all the features that
                CapMap has to offer, they need to request access for
                ‘Authenticated Role’ access.
              </p>
              <Button
                variant="success"
                className={`${styles.btnCustomSuccess} ${styles.btnRequestAccess}`}
                // onClick={showElevatedAccessModalHandler}
                onClick={compareUserType}
              >
                Request elevated access
              </Button>

              <Modal
                show={showElevatedAccessModal}
                onHide={closeElevatedAccessModalHandler}
                backdrop="static"
                keyboard={false}
                className={styles.modalRequestAccess}
              >
                <Modal.Title>
                  <Container
                    className={`d-flex justify-content-center ${styles.containerImgModalTitle}`}
                  >
                    <img
                      src={imgInfoBackground}
                      className={styles.imgInfoBgRequestAccessModal}
                      alt=""
                    />

                    <img
                      src={imgInfo}
                      className={styles.imgInfoRequestAccessModal}
                      alt=""
                    />
                  </Container>
                  <Container
                    className={`d-flex justify-content-center ${styles.containerTextModalTitle}`}
                  >
                    <h3 className=""> Request elevated access</h3>
                  </Container>
                </Modal.Title>

                <Modal.Body>
                  {/* <p className="">
                    Submit a request either for yourself or you can leave an
                    email address below and our team will get back to you
                    shortly.
                  </p> */}

                  <Container className="d-flex justify-content-center my-4">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={submitRequestElevatedAccessForm}
                      id="requestElevatedAccessForm"
                    >
                      <Form.Check
                        inline
                        label="For yourself"
                        name="radio-button-group"
                        type="radio"
                        id="inline-radio-button-1"
                        value="option1"
                        checked={selectedRadioButtonOption === "option1"}
                        onChange={handleRadioButtonOptionChange}
                      />

                      <Form.Check
                        inline
                        label="For other users"
                        name="radio-button-group"
                        type="radio"
                        id="inline-radio-button-2"
                        value="option2"
                        checked={selectedRadioButtonOption === "option2"}
                        onChange={handleRadioButtonOptionChange}
                        disabled
                      />

                      {selectedRadioButtonOption === "option1" ? (
                        <Form.Group>
                          <Form.Label
                            className={`${styles.emailAddressTitle} mt-2`}
                          >
                            Reason*
                          </Form.Label>

                          <Form.Control
                            className={styles.reasonContainer}
                            as="textarea"
                            id="reason"
                            name="reason"
                            value={reason}
                            onChange={handleInputReasonChange}
                            required
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            Please provide a valid reason.
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      ) : (
                        <div className={`mt-4`}>
                          <Form.Group>
                            <Form.Label className={styles.emailAddressTitle}>
                              Email addresses
                            </Form.Label>

                            <Form.Control
                              className={styles.emailContainer}
                              type="email"
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label
                              className={`${styles.emailAddressTitle} mt-2`}
                            >
                              Reason
                            </Form.Label>

                            <Form.Control
                              className={styles.reasonContainer}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                      )}
                    </Form>
                  </Container>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                  <Button
                    variant="outline-success"
                    className="ml-5"
                    onClick={closeElevatedAccessModalHandler}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="success"
                    type="submit"
                    onClick={closeElevatedAccessModalHandler}
                    form="requestElevatedAccessForm"
                    disabled={disableButton}
                  >
                    Request access
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Container>
        </Container>
      </div>

      {/* Contact */}
      <div
        className={`${styles.contentBodyContact} d-flex justify-content-center`}
      >
        <p className={`${styles.contactDescription} mt-5`}>
          Have a question or need help? Please contact our support team
        </p>

        <Button
          variant="success"
          className={`${styles.btnCustomSuccess} ${styles.btnContact} mt-5 mx-4`}
        >
          <a
            href="mailto:cspopsassets@deloitte.com?subject=Request for Support - [Brief Description of the Issue]&body=Dear Support Team,%0d%0a%0d%0a[Description of the Issue]%0d%0a%0d%0a
            "
            style={{ textDecoration: "none", color: "#ffffff" }}
          >
            Contact for support
          </a>
        </Button>
      </div>

      <Modal
        show={showAlreadyAuthenticatedModal}
        onHide={closeAlreadyAuthenticatedModalHandler}
        backdrop="static"
        keyboard={false}
        className={styles.modalRequestAccess}
      >
        <Modal.Title>
          <Container
            className={`d-flex justify-content-center ${styles.containerImgModalTitle}`}
          >
            <img
              src={imgInfoBackground}
              className={styles.imgInfoBgRequestAccessModal}
              alt=""
            />

            <img
              src={imgInfo}
              className={styles.imgInfoRequestAccessModal}
              alt=""
            />
          </Container>
          <Container
            className={`d-flex justify-content-center ${styles.containerTextModalTitle}`}
          >
            <h3 className=""> Request elevated access</h3>
          </Container>
        </Modal.Title>

        <Modal.Body>
          <Container className="d-flex justify-content-center my-4">
            <h6>You are already an authorized user!</h6>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="outline-success"
            onClick={closeAlreadyAuthenticatedModalHandler}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Home;

import styles from "./UserManagement.module.css";

import { useState } from "react";

import Navbar from "../Navbar/Navbar";

import ElevationRequests from "../ElevationRequests/ElevationRequests";
import AllUsers from "../AllUsers/AllUsers";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserManagement = (props) => {
  const [showElevationRequestTable, setShowElevationRequestTable] =
    useState(true);

  const [showAllUsersTable, setShowAllUsersTable] = useState(false);

  const [isActiveAllUsersTable, setIsActiveAllUsersTable] = useState(false);
  const [isActiveElevationRequestsTable, setIsActiveElevationRequestsTable] =
    useState(true);

  const displayElevationRequestTable = () => {
    if (showElevationRequestTable === false) {
      setShowElevationRequestTable(true);
    }

    setShowAllUsersTable(false);
  };

  const displayAllUsersTable = () => {
    if (showAllUsersTable === false) {
      setShowAllUsersTable(true);
    }
    setShowElevationRequestTable(false);
  };

  const handleClickAllUsersTable = () => {
    setIsActiveAllUsersTable(true);
    setIsActiveElevationRequestsTable(false);
  };

  const handleClickElevationRequestsTable = () => {
    setIsActiveElevationRequestsTable(true);
    setIsActiveAllUsersTable(false);
  };

  return (
    <>
      <div>
        <ToastContainer />
        <Navbar isAdmin={true} />

        <Container className={styles.containerUserManagementPage}>
          <section className="mt-4">
            <div className="d-flex">
              <h3 className={styles.userManagementPageMainTitle}>
                User Management
              </h3>
            </div>
            <p className={styles.userManagementPageDescription}>
              Here you can make the changes in user roles and user profiles
            </p>
          </section>

          <section className="mt-5">
            <div className="mx-2">
              <button
                className={
                  isActiveElevationRequestsTable
                    ? `${styles.userManagementPageElevationRequestsTableTitleActive} mx-3`
                    : `${styles.userManagementPageElevationRequestsTableTitle} mx-3`
                }
                onClick={() => {
                  displayElevationRequestTable();
                  handleClickElevationRequestsTable();
                }}
              >
                All elevation requests
              </button>
              <button
                className={
                  isActiveAllUsersTable
                    ? `${styles.userManagementPageAllUsersTableTitleActive} mx-3`
                    : `${styles.userManagementPageAllUsersTableTitle} mx-3`
                }
                onClick={() => {
                  displayAllUsersTable();
                  handleClickAllUsersTable();
                }}
              >
                All users
              </button>
            </div>

            <Card className={styles.userManagementPageCard}>
              {showElevationRequestTable && <ElevationRequests />}
              {showAllUsersTable && <AllUsers />}
            </Card>
          </section>
        </Container>
      </div>
    </>
  );
};

export default UserManagement;

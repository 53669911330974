import React, { useState } from "react";
import styles from "./BodyContent.module.css";
import AddCapability from "../AddCapability/AddCapability";
import RequestElevatedAccess from "../RequestElevatedAccess/RequestElevatedAccess";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const BodyContent = () => {
  //Getting Type of User

  let isAdmin = Cookies.get("isAdmin") === "true";
  let isAdminOrApproved = Cookies.get("isAdminOrApproved") === "true";

  const navigate = useNavigate();
  //Track to check whether clicked on RequestElevatedAccess button or not
  const [showComponent, setShowComponent] = useState(false);

  //Handle RequestElevatedAccess button
  const Show = () => {
    setShowComponent(!showComponent);
    navigate("/");
  };

  //Track selected user from dropdown b
  const [selectedUser, setSelectedUser] = useState("public");

  const handleUserChange = (value) => {
    setSelectedUser(value);
  };

  console.log("in body", isAdmin);
  return (
    <>
      <Navbar isAdmin={isAdmin} />

      <div className={styles.topContent}>
        {/*Capability Page top Content*/}
        <div className={styles.content}>
          <h1 className={styles.contentTitle}>Capabilities</h1>
          {isAdminOrApproved === false && isAdmin === false && (
            <button className={styles.showCapability} onClick={Show}>
              Request elevated access
            </button>
          )}

          {showComponent && <RequestElevatedAccess />}
        </div>
        {/* <p className={styles.description}>
          This business capability model provides structured representations of
          all firm's business capabilities and their relationships
        </p> */}
        <p className={styles.description}>
          <br></br>
        </p>
      </div>

      <AddCapability
        selectedUser={selectedUser}
        handleUserChange={handleUserChange}
      />
    </>
  );
};

export default BodyContent;

import "./Navbar.css";
import iconLogo from "../../assets/images/letter-vector.svg";
import iconUserProfilePFP from "../../assets/images/user-pfp-icon.svg";

import { NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { getMSGraphPhoto } from "../../apis/MSGraphAPI.js";
import { useState, useEffect } from "react";
import { Buffer } from "buffer/";

const Navbar = ({ isAdmin }) => {
  // const [isClicked, setIsClicked] = useState(false);
  const { instance } = useMsal();
  const accountInfo = instance.getActiveAccount();

  const [profilePicture, setProfilePicture] = useState(undefined);

  useEffect(() => {
    try {
      if (!profilePicture) {
        getMSGraphPhoto(instance).then((response) => {
          const photo = Buffer.from(response.data, "binary").toString("base64");
          setProfilePicture(photo);
        });
      }
    } catch (error) {
      console.log(error);
      setProfilePicture(undefined);
    }
  }, [accountInfo]);

  console.log("Printing in navbar", isAdmin);
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
        <div className="container-fluid">
          <a className="navbar-brand mx-3">
            <img
              alt=""
              src={iconLogo}
              width="30"
              height="30"
              className="d-inline-block align-top navbar-icon-logo mt-2"
            />
            <span className="navbar-divider mx-2">|</span>
            <span className="navbar-project-title">CapMap</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li
                className="nav-item mx-3"
                // {isClicked ? id="" :id=""}
                // onClick={isClicked}
              >
                <NavLink to="/" className="nav-link">
                  Home
                </NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink to="/capabilities" className="nav-link">
                  Capabilities
                </NavLink>
              </li>

              {/* <li className="nav-item mx-3">
                <Link to="#" className="nav-link">
                  Documents
                </Link>
              </li> */}
              {isAdmin && (
                <li className="nav-item mx-3">
                  <NavLink to="/user-management" className="nav-link">
                    User Management
                  </NavLink>
                </li>
              )}
            </ul>
            <div className="d-flex" style={{ display: "inline-block" }}>
              {/* <img src={iconAlarm} className="mx-2" />

              <img src={iconHelp} className="mx-2" /> */}

              <div className="d-flex navbar-container-user-profile mx-1">
                <div
                  className="user-type-text my-1"
                  style={{
                    display: "block",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 4,
                    margin: "0 auto",
                  }}
                >
                  {accountInfo?.name ?? "UserName"}
                </div>
                {profilePicture ? (
                  <img
                    alt="profilePicture"
                    src={`data:image/png;base64, ${profilePicture}`}
                    className="icon-user-profile-pfp"
                    style={{ display: "block", margin: "0 auto" }}
                  />
                ) : (
                  <img
                    alt="profilePicture"
                    src={iconUserProfilePFP}
                    className="icon-user-profile-pfp"
                    style={{ display: "block", margin: "0 auto" }}
                  />
                )}
              </div>

              {/* <div class="dropdown mx-3">
                <a
                  className=""
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={iconDropdownArrow} className="mt-2" />
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li>
                    <a className="dropdown-item" href="#">
                      <div className="d-flex">
                        <img
                          src={iconUserProfilePFP}
                          className="dropdown-item-icon-user-profile-pfp"
                        />
                        <div className="d-block mx-2">
                          <span className="user-profile-name">
                            Andrew Rodrigues
                          </span>
                          <br />
                          <span className="user-profile-designation">
                            Manager
                          </span>
                        </div>
                      </div>
                    </a>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <button class="dropdown-item" type="button">
                      Logout
                    </button>
                  </li>
                </ul>
              </div> */}
            </div>
            {/* <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-outline-success" type="submit">
                Search
              </button>
            </form> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

import React, { createContext, useContext, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// The color palette revolves around Deloitte Green
const variantMapping = {
  success: {
    title: "Success",
    headerColor: "#DDEFE8",
    bodyColor: "#26890D",
  },
  error: {
    title: "Error",
    headerColor: "#D0D0CE",
    bodyColor: "#DA291C",
  },
  info: {
    title: "Information",
    headerColor: "#A0DCFF",
    bodyColor: "#007CB0",
  },
  warning: {
    title: "Warning",
    headerColor: "#E3E48D",
    bodyColor: "#ED8B00",
  },
};
const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, variant) => {
    setNotifications([...notifications, { message, variant, id: Date.now() }]);
  };

  const removeNotification = (id) => {
    setNotifications(
      notifications.filter((notification) => notification.id !== id)
    );
  };

  return (
    <NotificationContext.Provider value={addNotification}>
      {children}
      <ToastContainer position="top-center" className="p-3">
        {notifications.map((notification) => (
          <Toast
            key={notification.id}
            onClose={() => removeNotification(notification.id)}
            show={true}
            // bg={
            //   notification.variant === "error" ? "danger" : notification.variant
            // }
            delay={3000}
            autohide
            animation={true}
            style={{
              border: "0px",
              backgroundColor:
                variantMapping[notification.variant]["bodyColor"],
            }}
          >
            <Toast.Header
              style={{
                backgroundColor:
                  variantMapping[notification.variant]["headerColor"],
              }}
            >
              <strong className="me-auto">
                {variantMapping[notification.variant]["title"]}
              </strong>
            </Toast.Header>

            <Toast.Body className="text-white">
              {notification.message}
            </Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

// AppContext.js
import React, { createContext, useState } from "react";

// Create the context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  // Define your state variables here
  const [isAdmin, setIsAdmin] = useState(false);

  const [isAdminOrApproved, setIsAdminOrApproved] = useState(false);

  // Create an object with the state values and any functions you want to expose
  const state = {
    isAdmin,
    setIsAdmin,
    isAdminOrApproved,
    setIsAdminOrApproved,
  };

  // Provide the state values and functions to the components
  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

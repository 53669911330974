import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import imgRequestAccess from "../../assets/images/DEL Illustration Brand Positioning 11_lo 1.svg";
import imgInfo from "../../assets/images/img-info-request-access-modal.svg";
import imgInfoBackground from "../../assets/images/img-info-bg-request-access-modal.svg";
import styles from "./RequestElevatedAccess.module.css";

const RequestElevatedAccess = () => {
  const [showElevatedAccessModal, setShowElevatedAccessModal] = useState(true);

  const closeElevatedAccessModalHandler = () =>
    setShowElevatedAccessModal(false);
  const showElevatedAccessModalHandler = () => setShowElevatedAccessModal(true);

  const [selectedRadioButtonOption, setSelectedRadioButtonOption] =
    useState("option1");

  const handleRadioButtonOptionChange = (e) => {
    setSelectedRadioButtonOption(e.target.value);
  };

  return (
    <>
      {/* <Modal
        show={showElevatedAccessModal}
        onHide={closeElevatedAccessModalHandler}
        backdrop="static"
        keyboard={false}
        className="modal-request-access"
      >
        <Modal.Title>
          <Container
            className="d-flex justify-content-center container-img-modal-title"
            style={{ height: "0px" }}
          >
            <img
              src={imgInfoBackground}
              className="img-info-bg-request-access-modal"
            />

            <img src={imgInfo} className="img-info-request-access-modal" />
          </Container>
          <Container className="d-flex justify-content-center container-text-modal-title">
            <h3 className=""> Request elevated access</h3>
          </Container>
        </Modal.Title>

        <Modal.Body>
          <p className="modal-desc">
            Submit a request either for yourself or you can leave an email
            address below and our team will get back to you shortly.
          </p>

          <Container className="d-flex justify-content-center my-4">
            <Form>
              <Form.Check
                inline
                label="For yourself? (default)"
                name="radio-button-group"
                type="radio"
                id="inline-radio-button-1"
              />

              <Form.Check
                inline
                label="For other users?"
                name="radio-button-group"
                type="radio"
                id="inline-radio-button-2"
              />
            </Form>
          </Container>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-success"
            className="ml-5"
            onClick={closeElevatedAccessModalHandler}
          >
            Return to home
          </Button>

          <Button variant="success">Request access</Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={showElevatedAccessModal}
        onHide={closeElevatedAccessModalHandler}
        backdrop="static"
        keyboard={false}
        className={styles.modalRequestAccess}
      >
        <Modal.Title>
          <Container
            className={`d-flex justify-content-center ${styles.containerImgModalTitle}`}
          >
            <img
              src={imgInfoBackground}
              className={styles.imgInfoBgRequestAccessModal}
              alt=""
            />

            <img
              src={imgInfo}
              className={styles.imgInfoRequestAccessModal}
              alt=""
            />
          </Container>
          <Container
            className={`d-flex justify-content-center ${styles.containerTextModalTitle}`}
          >
            <h3 className=""> Request elevated access</h3>
          </Container>
        </Modal.Title>

        <Modal.Body>
          <p className="">
            Submit a request either for yourself or you can leave an email
            address below and our team will get back to you shortly.
          </p>

          <Container className="d-flex justify-content-center my-4">
            <Form>
              <Form.Check
                inline
                label="For yourself? (default)"
                name="radio-button-group"
                type="radio"
                id="inline-radio-button-1"
                value="option1"
                checked={selectedRadioButtonOption === "option1"}
                onChange={handleRadioButtonOptionChange}
              />

              <Form.Check
                inline
                label="For other users?"
                name="radio-button-group"
                type="radio"
                id="inline-radio-button-2"
                value="option2"
                checked={selectedRadioButtonOption === "option2"}
                onChange={handleRadioButtonOptionChange}
              />

              {selectedRadioButtonOption === "option1" ? (
                ""
              ) : (
                <div className={`mt-4`}>
                  <Form.Group>
                    <Form.Label className={styles.emailAddressTitle}>
                      Email addresses
                    </Form.Label>

                    <Form.Control
                      className={styles.emailContainer}
                      type="email"
                    />
                  </Form.Group>

                  {/* <Form.Label className={styles.emailAddressTitle}>
                            Email addresses
                          </Form.Label>
                          <div className={styles.emailContainer}></div>
                          <Form.Control
                            as="textarea"
                            placeholder="Enter your message"
                            defaultValue=""
                          /> */}
                </div>
              )}
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-success"
            className="ml-5"
            onClick={closeElevatedAccessModalHandler}
          >
            Cancel
          </Button>

          <Button variant="success">Request access</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestElevatedAccess;

import React, { useState } from "react";
import styles from './Accordian.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Accordion=({ title, children,open})=> {

  const [isOpen, setIsOpen] = useState(open);

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
      
        <span className={styles.angleDownIcon}>
        <FontAwesomeIcon icon={faAngleDown} rotation={isOpen ? 180 : 0} />
        </span>
        <h3 className={styles.accordionTitle}>{title}</h3>
      </div>
      {isOpen && <div className="accordion-body">{children}</div>}
    </div>
  );
}

export default Accordion;

import styles from "./ElevationRequests.module.css";
import axios from "axios";
import { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

const ElevationRequests = () => {
  const formatDateInput = (stringDate) => {
    let currentDate = new Date(stringDate);
    currentDate.setDate(currentDate.getDate());
    let date = currentDate.toUTCString().substring(5, 17);
    return date;
  };

  const approveElevationRequestButtonHandler = () => {
    toast.success("You have approved a request successfully", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const denyElevationRequestButtonHandler = () => {
    toast.error("You have denied a request", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // GET API for all pending elevation requests

  const [allElevationRequests, setAllElevationRequests] = useState([]);

  const getElevationRequestDetails = async () => {
    await axios
      .get(apiUrl + "/user_manager/pending_users")
      .then((response) => {
        setAllElevationRequests(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // EDIT API for on click of Approve button

  const [selectedItem, setSelectedItem] = useState({});

  const approveElevationRequest = (event, userDetails) => {
    const options = {
      headers: { "content-type": "text/plain" },
    };

    const formData = {
      email: userDetails,
      status: "Approved"
    };

    axios
      .put(`${apiUrl}/user_manager/update_status`, formData, options)
      .then((response) => {
        console.log(response.data);
        getElevationRequestDetails();
      })
      .catch((err) => console.log(err));
  };

  const denyElevationRequest = (event, userDetails) => {
    const formData = {
      email: userDetails,
      status: "Deny"
    };

    axios
      .put(`${apiUrl}/email_notification`, formData)
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getElevationRequestDetails();
  }, []);

  return (
    <div>
      <Card.Body className={styles.cardBodyElevationRequests}>
        <Card.Title className="mb-3 d-flex justify-content-between align-items-center">
          <span className="card-title-elevation-requests mx-2">
            List of all elevated requests raised by the users
          </span>
        </Card.Title>

        <Table className="table-elevation-requests" responsive>
          <thead className={styles.tableElevationRequestsHead}>
            <tr>
              <th className={styles.tableElevationRequestsHeaderTitle}>Name</th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Email address
              </th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Requested by
              </th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Access type
              </th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Requested on
                <span className="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.iconArrowDown} bi bi-arrow-down`}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                    />
                  </svg>
                </span>
              </th>
              <th
                className={`${styles.tableElevationRequestsHeaderTitle} ${styles.tableHeaderReason}`}
              >
                Reason
              </th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {allElevationRequests.map((item) => {
              return (
                <tr key={item.id}>
                  <td className={styles.tableElevationRequestsDataText}>
                    {item.name}
                  </td>
                  <td className={styles.tableElevationRequestsDataText}>
                    {item.email}
                  </td>
                  <td className={styles.tableElevationRequestsDataText}>
                    {item.createdBy}
                  </td>
                  <td
                    className={`${styles.tableElevationRequestsDataText} ${styles.tableElevationRequestsDataTextAccessType}`}
                  >
                    {item.accessType}
                  </td>
                  <td
                    className={`${styles.tableElevationRequestsDataText} ${styles.tableElevationRequestsDataTextRequestDate}`}
                  >
                    {formatDateInput(item.requestedOn)}
                  </td>
                  <td
                    className={`${styles.tableElevationRequestsDataText} ${styles.reasonTextData}`}
                  >
                    {item.reason}
                  </td>

                  <td className={styles.tableElevationRequestsDataText}>
                    <Button
                      variant="success"
                      className={styles.deloitteButton}
                      onClick={(e) => {
                        approveElevationRequestButtonHandler();
                        setSelectedItem(item);
                        approveElevationRequest(e, item.email);
                      }}
                    >
                      Approve
                    </Button>

                    <Button
                      variant="outline-success"
                      className={`${styles.deloitteButton} mx-3`}
                      onClick={(e) => {
                        denyElevationRequestButtonHandler();
                        denyElevationRequest(e, item.email);
                      }}
                    >
                      Deny
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </div>
  );
};

export default ElevationRequests;

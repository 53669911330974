import styles from "./AllUsers.module.css";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import imgIconSearch from "../../assets/images/search-icon.svg";
import imgInfo from "../../assets/images/img-info-request-access-modal.svg";
import imgInfoBackground from "../../assets/images/img-info-bg-request-access-modal.svg";
import imgDanger from "../../assets/images/img-danger-remove-user-modal.svg";
import imgDangerBackground from "../../assets/images/img-danger-bg-remove-user-modal.svg";

const apiUrl = process.env.REACT_APP_API_URL;

const AllUsers = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [showAddUserSuccessfulModal, setShowAddUserSuccessfulModal] =
    useState(false);
  const [showEditUserSuccessfulModal, setShowEditUserSuccessfulModal] =
    useState(false);
  const [showRemoveUserSuccessfulModal, setShowRemoveUserSuccessfulModal] =
    useState(false);

  const closeAddUserModalHandler = () => setShowAddUserModal(false);
  const showAddUserModalHandler = () => setShowAddUserModal(true);

  const closeEditUserModalHandler = () => setShowEditUserModal(false);
  const showEditUserModalHandler = () => setShowEditUserModal(true);

  const closeRemoveUserModalHandler = () => setShowRemoveUserModal(false);
  const showRemoveUserModalHandler = () => setShowRemoveUserModal(true);

  const closeAddUserSuccessfulModalHandler = () =>
    setShowAddUserSuccessfulModal(false);
  const showAddUserSuccessfulModalHandler = () => {
    setShowAddUserSuccessfulModal(true);
  };

  const closeEditUserSuccessfulModalHandler = () =>
    setShowEditUserSuccessfulModal(false);
  const showEditUserSuccessfulModalHandler = () => {
    setShowEditUserSuccessfulModal(true);
  };

  const closeRemoveUserSuccessfulModalHandler = () =>
    setShowRemoveUserSuccessfulModal(false);
  const showRemoveUserSuccessfulModalHandler = () => {
    setShowRemoveUserSuccessfulModal(true);
  };

  // Add New User Form

  const [validated, setValidated] = useState(false);

  const formatDateInput = (stringDate) => {
    let currentDate = new Date(stringDate);
    currentDate.setDate(currentDate.getDate());
    let date = currentDate.toUTCString().substring(5, 17);
    return date;
  };

  // GET API for All Users in the System
  const [allUsers, setAllUsers] = useState([]);

  const getUserDetails = async () => {
    const response = await axios
      .get(apiUrl + "/user_manager/all_users")
      .then((response) => {
        // console.log(response);
        setAllUsers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // POST API for Adding New User to the System
  const { instance } = useMsal();
  let accountInfo = instance.getActiveAccount()

  const [formAddNewUserData, setFormAddNewUserData] = useState({
    name: "",
    email: "",
    accessLevel: "",
    accessBy: accountInfo ? accountInfo.username : "admin@deloitte.com",
  });

  const { name, email, accessLevel, accessBy } = formAddNewUserData;

  const submitUserDetails = (event, userDetails) => {
    event.preventDefault();

    const options = {
      headers: { "content-type": "text/plain" },
    };

    const formData = {
      name: name,
      email: email,
      accessLevel: accessLevel,
      accessBy: accessBy
    };

    axios
      .post(apiUrl + "/user_manager", formData, options)
      .then((response) => {
        // console.log(response.data);
        setAllUsers([...allUsers, response.data]);
        setFormAddNewUserData({
          name: "",
          email: "",
          accessLevel: "",
          accessBy: accountInfo ? accountInfo.username : "admin@deloitte.com",
        });
        getUserDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onHandleNewUserInputChange = (event) => {
    setFormAddNewUserData({
      ...formAddNewUserData,
      [event.target.name]: event.target.value,
    });
  };

  // EDIT API for Updating User Details

  const [selectedItem, setSelectedItem] = useState({});

  const editUserDetails = (event, userDetails) => {
    event.preventDefault();
    // const history = useHistory();

    const options = {
      headers: { "content-type": "text/plain" },
    };

    const formData = {
      name: userDetails.name,
      email: userDetails.emailID,
      accessLevel: userDetails.accessLevel
    };

    axios
      .put(`${apiUrl}/user_manager/edit`, formData, options)
      .then((response) => {
        setFormAddNewUserData(response.data);
        getUserDetails();
      })
      .catch((err) => console.log(err));

    //event.preventDefault();
    closeEditUserModalHandler();

    // history.push("/all-users");
  };

  // DELETE API for Removing User from the System

  const removeUserDetails = (event, deleteID) => {
    event.preventDefault();
    const options = {
      headers: { "content-type": "text/plain" },
    };
    const formData = {
      email: deleteID
    };
    console.log(formData);
    
    console.log(deleteID);
    axios
      .delete(`${apiUrl}/user_manager`, formData, options)
      .then((response) => {
        console.log("Deleted User Record", response.json);
        getUserDetails();
      })
      .catch((err) => console.log(err));

    closeRemoveUserModalHandler();
  };

  const onAddSuccessfulUserHandler = () => {
    showAddUserSuccessfulModalHandler();
    closeAddUserModalHandler();
  };

  const onEditSuccessfulUserHandler = () => {
    showEditUserSuccessfulModalHandler();
    closeEditUserModalHandler();
  };

  const onRemoveSuccessfulUserHandler = () => {
    showRemoveUserSuccessfulModalHandler();
    closeRemoveUserModalHandler();
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div>
      <Card.Body className={styles.cardBodyAllUsers}>
        <Card.Title className="mb-3 d-flex justify-content-between align-items-center">
          <span className={`${styles.cardTitleAllUsers} mx-2`}>
            List of all users
          </span>
          <div className="d-flex">
            <div className={`${styles.searchBarOutline} mx-4`}>
              <div className="input-group rounded">
                <input
                  type="search"
                  className={`form-control rounded ${styles.searchBar}`}
                  placeholder="Search"
                />
                <span
                  className="d-flex align-items-center mx-2"
                  id="search-addon"
                >
                  <img
                    src={imgIconSearch}
                    className={styles.iconSearch}
                    alt=""
                  />
                </span>
              </div>
            </div>

            <Button
              variant="success"
              className={`${styles.btnCustomSuccess} ${styles.btnAddUser}`}
              onClick={showAddUserModalHandler}
            >
              Add user
            </Button>
          </div>
        </Card.Title>

        <Table className="table-elevation-requests" responsive>
          <thead className={styles.tableElevationRequestsHead}>
            <tr>
              <th className={styles.tableElevationRequestsHeaderTitle}>Name</th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Email
              </th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Access Level
              </th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Status
              </th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Added On
              </th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Modified On
                <span className="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className={`${styles.iconArrowDown} bi bi-arrow-down`}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                    />
                  </svg>
                </span>
              </th>
              <th className={styles.tableElevationRequestsHeaderTitle}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {allUsers.map((item, index) => {
              console.log(allUsers);
              return (
                <tr key={item.id}>
                  <td className={styles.tableElevationRequestsDataText}>
                    {item.name}
                  </td>
                  <td className={styles.tableElevationRequestsDataText}>
                    {item.emailID}
                  </td>
                  <td className={styles.tableElevationRequestsDataText}>
                    {item.accessLevel}
                  </td>
                  <td className={styles.tableElevationRequestsDataText}>
                    {item.status}
                  </td>
                  <td
                    className={`${styles.tableElevationRequestsDataText} ${styles.tableElevationRequestsDataTextAccessType}`}
                  >
                    {formatDateInput(item.creationDate)}
                  </td>
                  <td
                    className={`${styles.tableElevationRequestsDataText} ${styles.tableElevationRequestsDataTextRequestDate}`}
                  >
                    {formatDateInput(item.modifyDate)}
                  </td>
                  <td>
                    <span
                      variant="success"
                      className={styles.btnEditUser}
                      onClick={() => {
                        setSelectedItem(item);
                        console.log(item.accessLevel);
                        showEditUserModalHandler();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className={`bi bi-pencil-square ${styles.iconEditUser}`}
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                    </span>

                    <span
                      variant="outline-success"
                      className={`${styles.btnRemoveUser} mx-3`}
                      onClick={() => {
                        setSelectedItem(item);
                        showRemoveUserModalHandler();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className={`bi bi-trash ${styles.iconRemoveUser}`}
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                      </svg>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>

      {/* Add User Modal */}
      <Modal
        show={showAddUserModal}
        onHide={closeAddUserModalHandler}
        backdrop="static"
        keyboard={false}
        className="modal-add-user"
      >
        <Modal.Title>
          <Container className="d-flex justify-content-center container-img-modal-title">
            <img
              src={imgInfoBackground}
              className={styles.imgInfoBgAddUserModal}
              alt=""
            />

            <img src={imgInfo} className={styles.imgInfoAddUserModal} alt="" />
          </Container>
          <Container
            className={`d-flex justify-content-center  ${styles.containerTextModalTitle}`}
          >
            <h3 className={styles.addUserModalTitle}>Add new user</h3>
          </Container>
          <Container
            className={`d-flex justify-content-center ${styles.containerTextModalSubTitle}`}
          >
            <h6 className={styles.addUserModalSubTitle}>
              You are adding new user in the system
            </h6>
          </Container>
        </Modal.Title>

        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submitUserDetails}>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom01">
                <Form.Label>User full name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter full name here"
                  id="name"
                  name="name"
                  value={name}
                  onChange={onHandleNewUserInputChange}
                  // isInvalid={name.trim() === ""}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your full name.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom02">
                <Form.Label>User email address</Form.Label>

                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  id="email"
                  name="email"
                  value={email}
                  // isInvalid={email.trim() === ""}
                  onChange={onHandleNewUserInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid email.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group>
                <Form.Label>Select access level</Form.Label>
                <Form.Select
                  id="accessLevel"
                  name="accessLevel"
                  value={accessLevel}
                  onChange={onHandleNewUserInputChange}
                  required
                >
                  <option selected>Select access level</option>
                  <option value="Authenticated">Authenticated</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-between">
              <Button
                variant="outline-success"
                className="ml-5"
                onClick={closeAddUserModalHandler}
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="success"
                // onClick={onAddSuccessfulUserHandler}
                onClick={closeAddUserModalHandler}
              >
                Add user
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAddUserSuccessfulModal}
        onHide={closeAddUserSuccessfulModalHandler}
        backdrop="static"
        keyboard={false}
        className="modal-add-user"
      >
        <Modal.Title>
          <Container className="d-flex justify-content-center container-img-modal-title">
            <img
              src={imgInfoBackground}
              className={styles.imgInfoBgAddUserModal}
              alt=""
            />

            <img src={imgInfo} className={styles.imgInfoAddUserModal} alt="" />
          </Container>
          <Container
            className={`d-flex justify-content-center  ${styles.containerTextModalTitle}`}
          >
            <h3 className={styles.addUserModalTitle}>
              User added successfully
            </h3>
          </Container>
        </Modal.Title>

        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom01">
                <Form.Label>User full name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pre-filled name comes here"
                  defaultValue=""
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom02">
                <Form.Label>User email address</Form.Label>

                <Form.Control
                  type="email"
                  placeholder="Pre-filled email address comes here"
                  defaultValue=""
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group>
                <Form.Label>Access level</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Pre-filled access level here"
                  defaultValue=""
                  readOnly
                />
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-between">
              <Button
                variant="outline-success"
                className="ml-5"
                onClick={closeAddUserSuccessfulModalHandler}
              >
                Done
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Edit User Modal */}
      <Modal
        show={showEditUserModal}
        onHide={closeEditUserModalHandler}
        backdrop="static"
        keyboard={false}
        className="modal-edit-user"
      >
        <Modal.Title>
          <Container className="d-flex justify-content-center container-img-modal-title">
            <img
              src={imgInfoBackground}
              className={styles.imgInfoBgEditUserModal}
              alt=""
            />

            <img src={imgInfo} className={styles.imgInfoEditUserModal} alt="" />
          </Container>
          <Container
            className={`d-flex justify-content-center  ${styles.containerTextModalTitle}`}
          >
            <h3 className={styles.editUserModalTitle}>Edit this user</h3>
          </Container>
          <Container
            className={`d-flex justify-content-center  ${styles.containerTextModalSubTitle}`}
          >
            <h6 className={styles.editUserModalSubTitle}>
              You are editing this user information
            </h6>
          </Container>
        </Modal.Title>

        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom01">
                <Form.Label>User full name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pre-filled name comes here"
                  defaultValue={selectedItem.name}
                  value={selectedItem.name}
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom02">
                <Form.Label>User email address</Form.Label>

                <Form.Control
                  type="email"
                  placeholder="Pre-filled email address comes here"
                  defaultValue={selectedItem.emailID}
                  value={selectedItem.emailID}
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group>
                <Form.Label>Update access level</Form.Label>
                <Form.Select
                  defaultValue={selectedItem.accessLevel}
                  value={selectedItem.accessLevel}
                  onChange={(event) => {
                    setSelectedItem((prev) => {
                      return { ...prev, accessLevel: event.target.value };
                    });
                  }}
                  required
                >
                  <option value="Authenticated">Authenticated</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-between">
              <Button
                variant="outline-success"
                className="ml-5"
                onClick={closeEditUserModalHandler}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="success"
                onClick={(e) => {
                  editUserDetails(e, selectedItem);
                }}
              >
                Update user
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showEditUserSuccessfulModal}
        onHide={closeEditUserSuccessfulModalHandler}
        backdrop="static"
        keyboard={false}
        className="modal-edit-user-successful"
      >
        <Modal.Title>
          <Container className="d-flex justify-content-center container-img-modal-title">
            <img
              src={imgInfoBackground}
              className={styles.imgInfoBgEditUserModal}
              alt=""
            />

            <img src={imgInfo} className={styles.imgInfoEditUserModal} alt="" />
          </Container>
          <Container
            className={`d-flex justify-content-center  ${styles.containerTextModalTitle}`}
          >
            <h3 className={styles.editUserModalTitle}>
              User updated successfully
            </h3>
          </Container>
        </Modal.Title>

        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom01">
                <Form.Label>User full name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pre-filled name comes here"
                  defaultValue=""
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom02">
                <Form.Label>User email address</Form.Label>

                <Form.Control
                  type="email"
                  placeholder="Pre-filled email address comes here"
                  defaultValue=""
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group>
                <Form.Label>New Access level</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Pre-filled access level here"
                  defaultValue=""
                  readOnly
                />
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-between">
              <Button
                variant="outline-success"
                className="ml-5"
                onClick={closeEditUserSuccessfulModalHandler}
              >
                Done
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Remove User Modal */}
      <Modal
        show={showRemoveUserModal}
        onHide={closeRemoveUserModalHandler}
        backdrop="static"
        keyboard={false}
        className="modal-remove-user"
      >
        <Modal.Title>
          <Container className="d-flex justify-content-center container-img-modal-title">
            <img
              src={imgDangerBackground}
              className={styles.imgInfoBgRemoveUserModal}
              alt=""
            />

            <img
              src={imgDanger}
              className={styles.imgInfoRemoveUserModal}
              alt=""
            />
          </Container>
          <Container
            className={`d-flex justify-content-center  ${styles.containerTextModalTitle}`}
          >
            <h3 className={styles.removeUserModalTitle}>Remove this user</h3>
          </Container>
          <Container className="d-flex justify-content-center container-text-modal-sub-title">
            <h6 className={styles.removeUserModalSubTitle}>
              You are removing this user from the system
            </h6>
          </Container>
        </Modal.Title>

        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom01">
                <Form.Label>User full name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pre-filled name comes here"
                  defaultValue={selectedItem.name}
                  value={selectedItem.name}
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom02">
                <Form.Label>User email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Pre-filled email address comes here"
                  defaultValue={selectedItem.emailID}
                  value={selectedItem.emailID}
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group>
                <Form.Label>Access level</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pre-filled access level here"
                  defaultValue={selectedItem.accessLevel}
                  value={selectedItem.accessLevel}
                  readOnly
                />
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-between">
              <Button
                variant="outline-success"
                className="ml-5"
                onClick={closeRemoveUserModalHandler}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="success"
                // onClick={onRemoveSuccessfulUserHandler}
                onClick={(e) => {
                  removeUserDetails(e, selectedItem.emailID);
                }}
              >
                Confirm
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showRemoveUserSuccessfulModal}
        onHide={closeRemoveUserSuccessfulModalHandler}
        backdrop="static"
        keyboard={false}
        className="modal-remove-user-successful"
      >
        <Modal.Title>
          <Container className="d-flex justify-content-center container-img-modal-title">
            <img
              src={imgInfoBackground}
              className={styles.imgInfoBgRemoveUserModal}
              alt=""
            />

            <img
              src={imgInfo}
              className={styles.imgInfoRemoveUserModal}
              alt=""
            />
          </Container>
          <Container
            className={`d-flex justify-content-center  ${styles.containerTextModalTitle}`}
          >
            <h3 className={styles.removeUserModalTitle}>
              User removed successfully
            </h3>
          </Container>
        </Modal.Title>

        <Modal.Body>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom01">
                <Form.Label>User full name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pre-filled name comes here"
                  defaultValue=""
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="14" controlId="validationCustom02">
                <Form.Label>User email address</Form.Label>

                <Form.Control
                  type="email"
                  placeholder="Pre-filled email address comes here"
                  defaultValue=""
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group>
                <Form.Label>Access level</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Pre-filled access level here"
                  defaultValue=""
                  readOnly
                />
              </Form.Group>
            </Row>

            <hr />

            <div className="d-flex justify-content-between">
              <Button
                variant="outline-success"
                className="ml-5"
                onClick={closeRemoveUserSuccessfulModalHandler}
              >
                Done
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllUsers;

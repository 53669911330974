import { useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import imgInfoBackground from "../../assets/images/img-success-bg.svg";
import imgInfo from "../../assets/images/img-checkmark.svg";
import styles from "./AddCapabilitySuccessModal.module.css";

const AddCapabilitySuccessModal = ({
  isSuccessModalShown,
  capabilityLevel,
  parentCapability,
  documents,
  formAddNewCapability,
}) => {
  const [isShowModal, setIsShowModal] = useState(isSuccessModalShown);

  // Determine the child capability level from the selected parent level
  const childCapabilityLevel = capabilityLevel.replace(
    /(\d+)/,
    (match, number) => {
      return String(Number(number) + 1);
    }
  );

  const handleClose = () => {
    setIsShowModal(false);
  };

  return (
    <Modal show={isShowModal} onHide={handleClose}>
      <Modal.Title>
        <Container
          className={`d-flex justify-content-center ${styles.containerImgModalTitle}`}
        >
          <img
            src={imgInfoBackground}
            className={styles.imgInfoBgRequestAccessModal}
            alt=""
          />
          <img
            src={imgInfo}
            className={styles.imgInfoRequestAccessModal}
            alt=""
          />
        </Container>
      </Modal.Title>
      <Modal.Body>
        <Container
          className={`d-flex justify-content-center ${styles.containerTextModalTitle}`}
        >
          <h3>Capability added successfully</h3>
        </Container>
        <Container
          className={`d-flex justify-content-center ${styles.containerTextModalSubtitle}`}
        >
          <h4>You have added this capability successfully</h4>
        </Container>
        <Container>
          <h5 className={styles.capabilityTitle}>
            <span className={styles.capabilityPill}>
              {childCapabilityLevel}
            </span>
            {formAddNewCapability.title}
          </h5>
          <p className={styles.capabilityDescription}>
            {formAddNewCapability.description}
          </p>
          <hr />
          <p className="form-label">Parent capability name</p>
          <p className={styles.capabilityDescription}>
            <span className={styles.capabilityPill}>{capabilityLevel}</span>
            {parentCapability}
          </p>
          <p className="form-label">Documents</p>
        </Container>
        <Container>
          <ul className={styles.capabilityDocumentsList}>
            {documents.map((data) => {
              return (
                <li
                  key={data.name}
                  className={styles.capabilityDocumentsListItem}
                >
                  {data.name} ({data.documents.length})
                </li>
              );
            })}
          </ul>
        </Container>
      </Modal.Body>
      <Modal.Footer className={`d-flex ${styles.modalFooter}`}>
        <Button
          variant="outline-success"
          className="ml-5"
          onClick={handleClose}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCapabilitySuccessModal;

import styles from "./CapabilityTreeNodeButton.module.css";

const CapabilityTreeNodeButton = ({ onClick, title, isButtonClicked }) => {
  return (
    <button
      className={`${styles.node} ${
        isButtonClicked === true ? styles.clicked : ""
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default CapabilityTreeNodeButton;

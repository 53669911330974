import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./components/App";
import { AppProvider } from "../src/apis/AppContext";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
msalInstance.initialize().then(async () => {
  try {
    await msalInstance.handleRedirectPromise();
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    } else if (msalInstance.getAllAccounts().length < 1) {
      msalInstance.loginRedirect();
    }
  
    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();
  
    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        const account = msalInstance.getActiveAccount() ?? msalInstance.getAllAccounts()[0]
        msalInstance.clearCache({account: account});
        msalInstance.logoutRedirect({postLogoutRedirectUri: msalConfig.auth.redirectUri});
      } 
    });
  } catch (error) {
    console.error(error)
  }

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <AppProvider>
          <App pca={msalInstance} />
        </AppProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
});
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./AddCapability.module.css";
import SelectIndustry from "../SelectIndustry/SelectIndustry";
import CapabilityTree from "../CapabilityTree/CapabilityTree";
import EditCapabilityModal from "../EditCapabilityModal/EditCapabilityModal";
import Accordian from "../Accordian/Accordian.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
const apiUrl = process.env.REACT_APP_API_URL;

const AddCapability = (props) => {
  const [isEditCapabilityModalShown, setIsEditCapabilityModalShown] =
    useState(false);

  const handleClose = () => {
    setIsEditCapabilityModalShown(false);

    // getCapabilityDetails();
  };

  const [industry, setIndustry] = useState([]);

  let isAdmin = Cookies.get("isAdmin") === "true";
  let isAdminOrApproved = Cookies.get("isAdminOrApproved") === "true";

  const [level, setLevel] = useState(0);

  const [parentTitleOfSelectedNode, setParentTitleOfSelectedNode] =
    useState("");

  const [hideChildCapabilities, setHideChildCapabilities] = useState();

  const [shouldRenderModel, setShouldRenderModel] = useState(false);

  const [hashCode, setHashCode] = useState(0);

  // Get Industry Data from Backend
  useEffect(() => {
    axios
      .get(apiUrl + "/domains")
      .then(function (response) {
        setIndustry(response.data.Industries);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const options = industry.flatMap((industry) => industry.name);
  const options1 = industry.flatMap((industry) =>
    industry.sectors.map((sector) => sector.name)
  );
  const options2 = industry.flatMap((industry) =>
    industry.sectors.flatMap((sector) => sector.sub_sectors)
  );

  //Track selected industry
  const [selectedIndustry, setSelectedIndustry] = useState("TMT");
  // "TMT"

  const handleSelectedIndustry = (selectedIndustry) => {
    setSelectedIndustry(selectedIndustry);
  };
  //Track selected sector
  const [selectedSector, setSelectedSector] = useState("Telecom");
  // "Telecom"

  const handleSelectedSector = (selectedSector) => {
    setSelectedSector(selectedSector);
  };

  //Track selected subsector
  const [selectedSubSector, setSelectedSubSector] = useState(
    "Communication Service Providers"
  );
  // "Communication Service Providers"
  const handleSelectedSubSector = (selectedSubSector) => {
    setSelectedSubSector(selectedSubSector);
  };

  //Track drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [drawerName, setDrawerName] = useState("");

  const [capabilityDescription, setCapabilityDescription] = useState("");

  const [childCapability, setChildCapability] = useState([]);

  const [documents, setDocuments] = useState([]);

  // const [businessModels,setBusinessModels]=useState([]);

  //calculate length
  let [totalChildCapability, setTotalChildCapability] = useState(0);

  let [totalBusinessModels, setTotalBusinessModels] = useState(0);

  let [totalArchitectures, setTotalArchitectures] = useState(0);

  let [totalJourneys, setTotalJourneys] = useState(0);

  //Accordion Titles
  let title = "Child Capabilities (" + totalChildCapability + ")";

  // Business model data
  let businessModels = [];

  // Architectures
  let architectures = [];

  // Journeys
  let journeys = [];

  //Toggle ReadMoreChildCapability
  const [readMoreChildCap, setReadMoreChildCap] = useState(true);
  const toggleReadMoreChildCap = () => {
    setReadMoreChildCap(!readMoreChildCap);
  };
  //Toggle ReadMore Business Models
  const [readMoreBusinessModels, setReadMoreBusinessModels] = useState(true);
  const toggleReadMoreBusinessModels = () => {
    setReadMoreBusinessModels(!readMoreBusinessModels);
  };
  //Toggle ReadMore Architectures
  const [readMoreArchitectures, setReadMoreArchitectures] = useState(true);
  const toggleReadMoreArchitectures = () => {
    setReadMoreArchitectures(!readMoreArchitectures);
  };
  //Toggle ReadMoreChildCapability
  const [readMoreJourneys, setReadMoreJourneys] = useState(true);
  const toggleReadMoreJourneys = () => {
    setReadMoreJourneys(!readMoreJourneys);
  };

  //User Dropdown Track
  const [selectedUser, setSelectedUser] = useState("admin");

  useEffect(() => {
    if (childCapability) {
      setTotalChildCapability(childCapability.length);
    } else {
      setTotalChildCapability(0);
    }
    if (businessModels) {
      setTotalBusinessModels(businessModels.length);
    } else {
      setTotalBusinessModels(0);
    }
    if (architectures) {
      setTotalArchitectures(architectures.length);
    } else {
      setTotalArchitectures(0);
    }
    if (journeys) {
      setTotalJourneys(journeys.length);
    } else {
      setTotalJourneys(0);
    }
  }, [childCapability, businessModels, architectures, journeys]);

  //Set all drawer values with capability data fetched from capability tree component
  const setDrawerValues = (data, name) => {
    setDrawerOpen(data);
    setDrawerName(name.title);
    setCapabilityDescription(name.description);
    setChildCapability(name.children);
    setDocuments(name.documents);
    setLevel(parseInt(name.level.substring(1)));
    setParentTitleOfSelectedNode(name.parent);
    setHashCode(name.hash_code);
  };

  if (documents) {
    documents.forEach((document) => {
      if (
        document.name.toLowerCase().trim() === "Journeys".toLowerCase().trim()
      ) {
        journeys = document.link;
      } else if (
        document.name.toLowerCase().trim() ===
        "Business Models".toLowerCase().trim()
      ) {
        businessModels = document.link;
      } else if (
        document.name.toLowerCase().trim() ===
        "Architectures".toLowerCase().trim()
      ) {
        architectures = document.link;
      }
    });
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setDrawerName("");
  };

  //Handle Child Capability Display according to logged in User
  useEffect(() => {
    if (
      (isAdmin === true && isAdminOrApproved === true) ||
      (isAdmin === false && isAdminOrApproved === true)
    ) {
      setHideChildCapabilities(false);
    } else {
      setHideChildCapabilities(true);
    }
  }, [isAdmin, isAdminOrApproved]);

  const handlePublicUserDocumentClick = () => {
    toast.error(
      "You need to request for elevated access to access the required documents",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  return (
    <>
      <ToastContainer />
      <div
        className={`${styles.appContainer} ${
          drawerName ? styles.sidebarOpen : ""
        }`}
      >
        <div className={styles.containerWrapper}>
          <Container className={styles.container1}>
            <Accordian
              title="Select Your Industry"
              open={true}
              className={styles.selectDomain}
            >
              <Row className={styles.secondRow}>
                <Col>Industry</Col>
                <Col>Sector</Col>
                <Col>Subsector</Col>
              </Row>
              <Row className={styles.thirdrow}>
                <Col>
                  <SelectIndustry
                    options={options}
                    onSelectChange={handleSelectedIndustry}
                    // initialValue="TMT"
                  />
                </Col>
                <Col>
                  <SelectIndustry
                    options={options1}
                    onSelectChange={handleSelectedSector}
                    // initialValue="Telecom"
                  />
                </Col>
                <Col>
                  <SelectIndustry
                    options={options2}
                    onSelectChange={handleSelectedSubSector}
                    // initialValue="Communication Service Providers"
                  />
                </Col>
              </Row>
            </Accordian>

            {/*show Tree when Industry,Sector and Subsector values are selected from dropdown*/}
            {/* {
          selectedOption ?( */}
            <Row className={styles.fourthRow}>
              <CapabilityTree
                drawerOpen={drawerOpen}
                onDrawerOpen={setDrawerValues}
                selectedUser={selectedUser}
                selectedIndustry={selectedIndustry}
                selectedSector={selectedSector}
                selectedSubSector={selectedSubSector}
                shouldRenderModel={shouldRenderModel}
                // newIndustry={newIndustry}
              />
            </Row>
          </Container>
        </div>

        {/*Sidebar to show capability details*/}
        {drawerName && (
          <div className={styles.drawer}>
            <div className={styles.capDesc}>
              <p>
                <b>ID: </b>
                {hashCode && hashCode.slice(0, 7)}
              </p>

              <div className={styles.icons}>
                {isAdminOrApproved === true && isAdmin === true && (
                  <div className={styles.tooltipEditCap}>
                    <button
                      className={styles.edit}
                      // onClick={() => setIsEditCapabilityModalShown(true)}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{ color: "#cccccc" }}
                      />
                    </button>

                    <span class={styles.tooltiptextEditCap}>
                      <br />
                      Edit Capability
                      <br />
                      <br />
                    </span>
                  </div>
                )}
                {isAdminOrApproved === true && isAdmin === true && (
                  <button className={styles.delete}>
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      style={{ color: "#cccccc" }}
                    />
                  </button>
                )}

                <button onClick={toggleDrawer} className={styles.close}>
                  <FontAwesomeIcon icon={faXmark} style={{ color: "green" }} />
                </button>
              </div>
              <p className={styles.capabilityName}>{drawerName}</p>

              <p className={styles.capabilityDescription}>
                {capabilityDescription}
              </p>
            </div>

            <div className={styles.options}>
              {((hideChildCapabilities === true && level < 2) ||
                hideChildCapabilities === false) && (
                <Accordian title={title} open={false}>
                  {childCapability ? (
                    totalChildCapability > 3 ? (
                      readMoreChildCap ? (
                        <div>
                          {childCapability.slice(0, 3).map((child) => (
                            <div>
                              <a className={styles.childDesc}>{child.title}</a>
                              <br />
                            </div>
                          ))}
                          <button
                            className={styles.readMoreChildCapability}
                            onClick={toggleReadMoreChildCap}
                          >
                            ...Read more
                          </button>
                        </div>
                      ) : (
                        <div>
                          {childCapability.map((child) => (
                            <div>
                              <a className={styles.childDesc}>{child.title}</a>
                              <br />
                            </div>
                          ))}
                          <button
                            className={styles.readMoreChildCapability}
                            onClick={toggleReadMoreChildCap}
                          >
                            ...Read Less
                          </button>
                        </div>
                      )
                    ) : (
                      <div>
                        {childCapability.map((child) => (
                          <div>
                            <a className={styles.childDesc}>{child.title}</a>
                            <br />
                          </div>
                        ))}
                      </div>
                    )
                  ) : (
                    <p className={styles.childDesc}>
                      {drawerName} do not have any child capabilities
                    </p>
                  )}
                </Accordian>
              )}

              <div className={styles.Documents}>
                <h6>Documents</h6>
                <Accordian
                  title={"Business models (" + businessModels.length + ")"}
                  open={false}
                >
                  {businessModels ? (
                    totalBusinessModels > 3 ? (
                      readMoreBusinessModels ? (
                        <div>
                          {isAdminOrApproved === true
                            ? businessModels
                                .slice(0, 3)
                                .map((businessModel) => (
                                  <div>
                                    <a
                                      className={styles.businessModels}
                                      href={businessModel.doc_url}
                                      target="_blank"
                                    >
                                      {businessModel.doc_name}
                                      {hideChildCapabilities}
                                    </a>
                                    <br />
                                  </div>
                                ))
                            : businessModels
                                .slice(0, 3)
                                .map((businessModel) => (
                                  <div>
                                    <a
                                      className={
                                        styles.businessModelsPublicUser
                                      }
                                      onClick={handlePublicUserDocumentClick}
                                      href="#"
                                    >
                                      {businessModel.doc_name}
                                      {hideChildCapabilities}
                                    </a>
                                    <br />
                                  </div>
                                ))}
                          <button
                            className={styles.readMoreChildCap}
                            onClick={toggleReadMoreBusinessModels}
                          >
                            ...Read more
                          </button>
                        </div>
                      ) : (
                        <div>
                          {isAdminOrApproved === true
                            ? businessModels.map((businessModel) => (
                                <div>
                                  <a
                                    className={styles.businessModels}
                                    href={businessModel.doc_url}
                                    target="_blank"
                                  >
                                    {businessModel.doc_name}
                                  </a>
                                  <br />
                                </div>
                              ))
                            : businessModels.map((businessModel) => (
                                <div>
                                  <a
                                    className={styles.businessModelsPublicUser}
                                    onClick={handlePublicUserDocumentClick}
                                    href="#"
                                  >
                                    {businessModel.doc_name}
                                  </a>

                                  <br />
                                </div>
                              ))}
                          <button
                            className={styles.readMoreChildCap}
                            onClick={toggleReadMoreBusinessModels}
                          >
                            ...Read Less
                          </button>
                        </div>
                      )
                    ) : (
                      <div>
                        {isAdminOrApproved === true
                          ? businessModels.slice(0, 3).map((businessModel) => (
                              <div>
                                <a
                                  className={styles.businessModels}
                                  href={businessModel.doc_url}
                                  target="_blank"
                                >
                                  {businessModel.doc_name}
                                </a>
                                <br />
                              </div>
                            ))
                          : businessModels.slice(0, 3).map((businessModel) => (
                              <div>
                                <a
                                  className={styles.businessModelsPublicUser}
                                  onClick={handlePublicUserDocumentClick}
                                  href="#"
                                >
                                  {businessModel.doc_name}
                                </a>
                                <br />
                              </div>
                            ))}
                      </div>
                    )
                  ) : (
                    <p></p>
                  )}
                </Accordian>
              </div>

              <div>
                <Accordian
                  title={"Architectures (" + architectures.length + ")"}
                  open={false}
                >
                  {architectures ? (
                    totalArchitectures > 3 ? (
                      readMoreArchitectures ? (
                        <div>
                          {isAdminOrApproved === true
                            ? architectures.slice(0, 3).map((Architecture) => (
                                <div>
                                  <a
                                    className={styles.businessModels}
                                    href={Architecture.doc_url}
                                    target="_blank"
                                  >
                                    {Architecture.doc_name}
                                  </a>
                                  <br />
                                </div>
                              ))
                            : architectures.slice(0, 3).map((Architecture) => (
                                <div>
                                  <a
                                    className={styles.businessModelsPublicUser}
                                    onClick={handlePublicUserDocumentClick}
                                    href="#"
                                  >
                                    {Architecture.doc_name}
                                  </a>
                                  <br />
                                </div>
                              ))}
                          <button
                            className={styles.readMoreChildCap}
                            onClick={toggleReadMoreArchitectures}
                          >
                            ...Read more
                          </button>
                        </div>
                      ) : (
                        <div>
                          {isAdminOrApproved === true
                            ? architectures.map((Architecture) => (
                                <div>
                                  <a
                                    className={styles.businessModels}
                                    href={Architecture.doc_url}
                                    target="_blank"
                                  >
                                    {Architecture.doc_name}
                                  </a>
                                  <br />
                                </div>
                              ))
                            : architectures.map((Architecture) => (
                                <div>
                                  <a
                                    className={styles.businessModelsPublicUser}
                                    onClick={handlePublicUserDocumentClick}
                                    href="#"
                                  >
                                    {Architecture.doc_name}
                                  </a>
                                  <br />
                                </div>
                              ))}
                          <button
                            className={styles.readMoreChildCap}
                            onClick={toggleReadMoreArchitectures}
                          >
                            ...Read Less
                          </button>
                        </div>
                      )
                    ) : (
                      <div>
                        {isAdminOrApproved === true
                          ? architectures.slice(0, 3).map((Architecture) => (
                              <div>
                                <a
                                  className={styles.businessModels}
                                  href={Architecture.doc_url}
                                  target="_blank"
                                >
                                  {Architecture.doc_name}
                                </a>
                                <br />
                              </div>
                            ))
                          : architectures.slice(0, 3).map((Architecture) => (
                              <div>
                                <a
                                  className={styles.businessModelsPublicUser}
                                  onClick={handlePublicUserDocumentClick}
                                  href="#"
                                >
                                  {Architecture.doc_name}
                                </a>
                                <br />
                              </div>
                            ))}
                      </div>
                    )
                  ) : (
                    <p></p>
                  )}
                </Accordian>
              </div>

              <div>
                <Accordian
                  title={"Journeys (" + journeys.length + ")"}
                  open={false}
                >
                  {journeys ? (
                    totalJourneys > 3 ? (
                      readMoreJourneys ? (
                        <div>
                          {isAdminOrApproved === true
                            ? journeys.slice(0, 3).map((Journey) => (
                                <div>
                                  <a
                                    className={styles.businessModels}
                                    href={Journey.doc_url}
                                    target="_blank"
                                  >
                                    {Journey.doc_name}
                                  </a>
                                  <br />
                                </div>
                              ))
                            : journeys.slice(0, 3).map((Journey) => (
                                <div>
                                  <a
                                    className={styles.businessModelsPublicUser}
                                    onClick={handlePublicUserDocumentClick}
                                    href="#"
                                  >
                                    {Journey.doc_name}
                                  </a>
                                  <br />
                                </div>
                              ))}
                          <button
                            className={styles.readMoreChildCap}
                            onClick={toggleReadMoreJourneys}
                          >
                            ...Read more
                          </button>
                        </div>
                      ) : (
                        <div>
                          {isAdminOrApproved === true
                            ? journeys.map((Journey) => (
                                <div>
                                  <a
                                    className={styles.businessModels}
                                    href={Journey.doc_url}
                                    target="_blank"
                                  >
                                    {Journey.doc_name}
                                  </a>
                                  <br />
                                </div>
                              ))
                            : journeys.map((Journey) => (
                                <div>
                                  <a
                                    className={styles.businessModelsPublicUser}
                                    onClick={handlePublicUserDocumentClick}
                                    href="#"
                                  >
                                    {Journey.doc_name}
                                  </a>
                                  <br />
                                </div>
                              ))}
                          <button
                            className={styles.readMoreChildCap}
                            onClick={toggleReadMoreJourneys}
                          >
                            ...Read Less
                          </button>
                        </div>
                      )
                    ) : (
                      <div>
                        {isAdminOrApproved === true
                          ? journeys.slice(0, 3).map((Journey) => (
                              <div>
                                <a
                                  className={styles.businessModels}
                                  href={Journey.doc_url}
                                  target="_blank"
                                >
                                  {Journey.doc_name}
                                </a>
                                <br />
                              </div>
                            ))
                          : journeys.slice(0, 3).map((Journey) => (
                              <div>
                                <a
                                  className={styles.businessModelsPublicUser}
                                  onClick={handlePublicUserDocumentClick}
                                  href="#"
                                >
                                  {Journey.doc_name}
                                </a>
                                <br />
                              </div>
                            ))}
                      </div>
                    )
                  ) : (
                    <p></p>
                  )}
                </Accordian>
              </div>
            </div>
          </div>
        )}
      </div>

      <EditCapabilityModal
        isEditCapabilityModalShown={isEditCapabilityModalShown}
        handleClose={handleClose}
        currentCapabilityName={drawerName}
        currentCapabilityDescription={capabilityDescription}
        currentCapabilityLevel={level}
        currentParentLevel={level - 1}
        currentParentTitle={parentTitleOfSelectedNode}
      />
    </>
  );
};

export default AddCapability;

import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const getCapabilityDetailsAPI = async ({ params }) => {
  // return await axios.get("http://127.0.0.1:5000/capability", { params });

  return await axios.get(
    `${apiUrl}/capability_manager?industry=${params.industry}&sector=${params.sector}&subsector=${params.subsector}`
  );
  // .then((response) => {
  //   setCapabilityData([response.data[0].model]);
  // })
  // .catch((error) => {
  //   console.error(error.message);
  // });
};

// The below API performs Add/Edit/Delete capability details operations

const addCapabilityDetailsAPI = async (formData) => {
  try {
    const options = {
      headers: { "content-type": "text/plain" },
    };

    return await axios.put(`${apiUrl}/capability_manager`, formData);
  } catch (error) {}
  // .then((response) => {
  //   console.log(response.data);
  // })
  // .catch((error) => {
  //   console.error(error.message);
  // });
};

const uploadCapabilityFileAPI = async (file, industry, sector, subsector) => {
  try {
    if (file) {
      const data = new FormData();
      data.append("capabilities", file);
      data.append("industry", industry);
      data.append("sector", sector);
      data.append("subsector", subsector);
      return (
        await axios.post(`${apiUrl}/capability_manager`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      )["data"];
    }
    return;
  } catch (error) {
    throw error;
  }
};

export const getDomainDetailsAPI = async ({ setIndustry }) => {
  await axios
    .get(apiUrl + "/domains")
    .then(function (response) {
      setIndustry(response.data.Industries);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const CapabilityAPI = {
  addCapabilityDetailsAPI,
  getCapabilityDetailsAPI,
  uploadCapabilityFileAPI,
};

export default CapabilityAPI;

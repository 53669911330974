import { useState } from "react";
import {
  Button,
  Col,
  Container,
  Collapse,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const UploadCapabilityModal = ({ show, handleClose, handleFileUpload }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = () => {
    handleFileUpload(file);
    hide();
  };

  const hide = () => {
    handleClose(false);
    setFile(null);
  };

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Capability File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile">
            <Form.Control
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hide}>
          Close
        </Button>
        <Button variant="success" onClick={handleSubmit} disabled={!file}>
          <FontAwesomeIcon icon={faUpload} /> Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadCapabilityModal;

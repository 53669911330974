import React, { useState } from "react";
import styles from "./SelectIndustry.module.css";

const SelectIndustry = (props) => {
  const [selectedOption, setSelectedOption] = useState();

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedOption(selectedOption);
    props.onSelectChange(selectedOption);
  };

  return (
    <div className={styles.main}>
      <select
        className={styles.selectIndustry}
        value={selectedOption}
        onChange={handleOptionChange}
      >
        {props.options.map((option) => (
          <option key={option.value} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
export default SelectIndustry;

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import imgTreeIcon from "../../assets/images/tree-icon.jpg";
import styles from "./EditCapabilityModal.module.css";
import "../../assets/styles/theme.css";
import CapabilityAPI from "../../apis/CapabilityAPI";

const EditCapabilityModal = ({
  isEditCapabilityModalShown,
  handleClose,
  currentCapabilityLevel,
  parentCapability,
  formEditCapability,
  handleEditFormCapability,
  selectedIndustry,
  selectedSector,
  selectedSubSector,
  capabilityTree,
  currentCapabilityName,
  currentCapabilityDescription,
  currentParentLevel,
  currentParentTitle,
}) => {
  const onHandleEditCapabilityInputChange = (event) => {
    handleEditFormCapability({
      ...formEditCapability,
      [event.target.name]: event.target.value,
    });
  };

  const submitEditCapabilityDetails = (event) => {
    event.preventDefault();

    const formData = {
      industry: selectedIndustry,
      sector: selectedSector,
      subsector: selectedSubSector,
      model: JSON.stringify(capabilityTree),
    };

    CapabilityAPI.addCapabilityDetailsAPI(formData)
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          handleClose();
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal show={isEditCapabilityModalShown} onHide={handleClose}>
      <Modal.Title></Modal.Title>

      <Modal.Body>
        <Form
          onSubmit={submitEditCapabilityDetails}
          id="submitEditCapabilityDetailsForm"
        >
          <span className="d-flex">
            <img
              src={imgTreeIcon}
              alt="mapping icon"
              width="15px"
              height="15px"
              className={styles.treeIcon}
            />
            <h2 className="h2 color-primary">Mapping</h2>
          </span>
          <Row>
            <Col>
              {/* <p className="form-label">Parent Capability Name</p> */}
              {/* <CapabilityTreeNodeButton title={parentCapability} /> */}
              <li className={styles.currentCapability}>
                L{currentCapabilityLevel} (Current level of capability)
              </li>
            </Col>
            <Col>
              <li className={styles.parentCapability}>
                L{currentParentLevel} ({currentParentTitle})
              </li>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Select new parent level</Form.Label>
                <Form.Select
                  id="accessLevel"
                  name="accessLevel"
                  disabled
                  required
                >
                  <option selected>L{currentParentLevel} level</option>
                  {/* <option value="Authenticated">Authenticated</option>
                    <option value="Admin">Admin</option> */}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Select new parent capability name</Form.Label>
                <Form.Select id="accessLevel" name="accessLevel" required>
                  <option selected>Select parent capability name</option>
                  {/* <option value="Authenticated">Authenticated</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="addCapabilityForm.capabilityName"
              >
                <Form.Label>Capability name</Form.Label>
                <Form.Control
                  type="text"
                  id="title"
                  name="title"
                  defaultValue={currentCapabilityName}
                  // value={formEditCapability.title}
                  // onChange={onHandleEditCapabilityInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Capability description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                name="description"
                defaultValue={currentCapabilityDescription}
                // value={formEditCapability.description}
                // onChange={onHandleEditCapabilityInputChange}
              />
            </Col>
          </Row>
          <hr />
        </Form>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        <Button
          variant="outline-success"
          className="ml-5"
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button variant="success" type="submit">
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCapabilityModal;

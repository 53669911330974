import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-white bg-opacity-50"
      style={{ zIndex: 2000 }}
    >
      <Spinner
        animation="border"
        variant="secondary"
        style={{ width: "4rem", height: "4rem" }}
      />
    </div>
  );
};

export default Loader;

import React, { useState, useEffect } from "react";
import styles from "./CapabilityTree.module.css";
import CapabilityTreeNode from "../CapabilityTreeNode/CapabilityTreeNode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPlus,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import AddCapabilityModal from "../AddCapabilityModal/AddCapabilityModal";
import AddCapabilitySuccessModal from "../AddCapabilitySuccessModal/AddCapabilitySuccessModal";
import UploadCapabilityModal from "../UploadCapabilityModal/UploadCapabilityModal";
import { useNotification } from "../Notification/NotificationToast";
import * as XLSX from "xlsx";
import CapabilityAPI from "../../apis/CapabilityAPI";
import Cookies from "js-cookie";
import { useLoader } from "../Loader/LoaderProvider";

const sampleDocumentData = [
  {
    name: "Business Models",
    documents: [
      {
        title: "Business Model Canvas",
        url: "https://example.com/business-model-canvas",
      },
      {
        title: "Value Proposition Canvas",
        url: "https://example.com/value-proposition-canvas",
      },
      {
        title: "Revenue Model Examples",
        url: "https://example.com/revenue-model-examples",
      },
    ],
  },
  {
    name: "Architecture",
    documents: [
      {
        title: "System Architecture Overview",
        url: "https://example.com/system-architecture-overview",
      },
      {
        title: "Microservices Architecture Patterns",
        url: "https://example.com/microservices-architecture-patterns",
      },
      {
        title: "Scalable Web Application Architecture",
        url: "https://example.com/scalable-web-app-architecture",
      },
      {
        title: "ChatGPT Rules!",
        url: "https://example.com/chatgpt-rules",
      },
    ],
  },
  {
    name: "Journeys",
    documents: [
      {
        title: "User Journey Mapping Guide",
        url: "https://example.com/user-journey-mapping-guide",
      },
      {
        title: "Customer Journey Examples",
        url: "https://example.com/customer-journey-examples",
      },
      {
        title: "Mobile App User Onboarding",
        url: "https://example.com/mobile-app-user-onboarding",
      },
    ],
  },
];

// Convert tree data to tabular format

const getMaxDepth = (node) => {
  if (!node.children || node.children.length === 0) {
    return 0;
  }
  let depths = node.children.map((child) => getMaxDepth(child));
  return 1 + Math.max(...depths);
};

const generateHeaders = (maxDepth) => {
  let headers = [];
  for (let i = 0; i <= maxDepth; i++) {
    headers.push(`L${i} Title`, `L${i} Description`);
  }
  return headers;
};

const convertToExcel = (treeData, industry, sector, subsector, title) => {
  const date = new Date();
  const formattedDateTime = date.toLocaleString(); // Format the time as per your requirement
  // const flattenData = treeData.map((node) => flattenNodes(node));
  let flattenedData = [];
  for (const obj of treeData) {
    flattenedData.push(...flattenNodes(obj));
  }
  let headers = generateHeaders(
    Math.max(...treeData.map((obj) => getMaxDepth(obj)))
  );
  flattenedData.unshift(headers);

  const worksheet = XLSX.utils.aoa_to_sheet(flattenedData);

  // Remove the first row (header row) from the worksheet
  // const range = XLSX.utils.decode_range(worksheet["!ref"]);
  // range.s.r += 1; // Start from the second row
  // worksheet["!ref"] = XLSX.utils.encode_range(range);

  // Apply formatting to the first row (header row)
  // Apply formatting to the first row (header row)
  // const firstRowRange = XLSX.utils.decode_range(worksheet["!ref"]);
  // for (let c = firstRowRange.s.c; c <= firstRowRange.e.c; c++) {
  //   const cellAddress = XLSX.utils.encode_cell({ r: firstRowRange.s.r, c });
  //   const cell = worksheet[cellAddress];

  //   // Create a new style object and assign the fill color
  //   const style = {
  //     fill: {
  //       fgColor: { rgb: "FFFF00" }, // Specify the color, e.g., yellow
  //     },
  //   };

  //   // Apply the style to the cell
  //   cell.s = style;
  // }

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Capability Details");
  XLSX.writeFile(
    workbook,
    industry +
      "_" +
      sector +
      "_" +
      "_" +
      subsector +
      "_" +
      title +
      "_" +
      formattedDateTime +
      ".xlsx"
  );
};

const flattenNodes = (node, parentRow = []) => {
  let currentRow = [...parentRow, node.title, node.description];

  if (node.children && node.children.length > 0) {
    let rows = [];
    for (let child of node.children) {
      rows.push(...flattenNodes(child, currentRow));
    }
    return rows;
  } else {
    return [currentRow];
  }
};

const CapabilityTree = ({
  onDrawerOpen,
  selectedUser,
  newIndustry,
  selectedIndustry,
  selectedSector,
  selectedSubSector,
  shouldRenderModel,
}) => {
  //Getting Type of User

  const addNotification = useNotification();
  const { showLoader, hideLoader } = useLoader();
  let isAdmin = Cookies.get("isAdmin") === "true";
  let isAdminOrApproved = Cookies.get("isAdminOrApproved") === "true";
  const [isSuccessModalShown, setIsSuccessModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerName, setDrawerName] = useState("");
  const [formAddNewCapability, setFormAddNewCapability] = useState({
    title: "",
    level: "",
    parent: "",
    description: "",
  });

  const [capabilityData, setCapabilityData] = useState([]);
  const [isPlusButtonDisabled, setIsPlusButtonDisabled] = useState(true);
  const [selectedCapabilityLevel, setSelectedCapabilityLevel] = useState("");

  const [isAddCapabilityModalShown, setIsAddCapabilityModalShown] =
    useState(false);

  const [showUploadCapabilityModal, setShowUploadCapabilityModal] =
    useState(false);

  const handleClose = () => {
    setIsAddCapabilityModalShown(false);

    getCapabilityDetails();
  };

  const getCapabilityDetails = async () => {
    showLoader();
    CapabilityAPI.getCapabilityDetailsAPI({
      params: {
        industry: selectedIndustry,
        sector: selectedSector,
        subsector: selectedSubSector,
      },
    })
      .then((response) => {
        let model = JSON.parse(response.data[response.data.length - 1].model);
        setCapabilityData(Array.isArray(model) ? model : [model]);
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        setCapabilityData([]);
        let errorMessage = "An unknown error occurred";

        if (err.response) {
          errorMessage =
            err.response.data.message ||
            err.response.data.error ||
            err.response.statusText ||
            errorMessage;
        } else if (err.request) {
          errorMessage = "No response received from the server";
        } else {
          errorMessage = err.message;
        }
        addNotification(errorMessage, "error");
        console.log(err);
      });
  };

  useEffect(() => {
    {
      getCapabilityDetails();
    }
  }, [selectedIndustry, selectedSector, selectedSubSector]);

  const handleSetFormAddNewCapability = (data) => setFormAddNewCapability(data);

  const setDrawerValues = (selectedNode) => {
    setDrawerName(selectedNode.title);
    // toggleDrawer(true);
    onDrawerOpen(drawerOpen, selectedNode);
    setSelectedCapabilityLevel(selectedNode.level);
    setIsPlusButtonDisabled(false);
  };

  const handleFileUpload = (file) => {
    showLoader();
    CapabilityAPI.uploadCapabilityFileAPI(
      file,
      selectedIndustry,
      selectedSector,
      selectedSubSector
    )
      .then((data) => {
        getCapabilityDetails();
        hideLoader();
        addNotification("Capability Uploaded Successfully", "success");
      })
      .catch((fault) => {
        hideLoader();
        let errorMessage = "An unknown error occurred";

        if (fault.response) {
          errorMessage =
            fault.response.data.message ||
            fault.response.data.error ||
            errorMessage;
        } else if (fault.request) {
          errorMessage = "No response received from the server";
        } else {
          errorMessage = fault.message;
        }
        addNotification(errorMessage, "error");
        console.log(fault);
      });
  };

  //Handle export icon clicked
  const handleExportClicked = () => {
    convertToExcel(
      capabilityData,
      selectedIndustry,
      selectedSector,
      selectedSubSector,
      capabilityData[0].title
    );
  };

  const handleUpdateModelClick = () => {
    setShowUploadCapabilityModal(true);
  };

  let levelsForUser;
  if (
    (isAdmin === true && isAdminOrApproved === true) ||
    (isAdmin === false && isAdminOrApproved === true)
  ) {
    levelsForUser = Number.MAX_SAFE_INTEGER; // Set the maximum level for the admin user
  } else {
    levelsForUser = 2; // Set the maximum level for the public user
  }

  return (
    <>
      <div className={styles.container}>
        {isAdminOrApproved === true && (
          <div className={styles.tooltipContainer}>
            {isAdmin && (
              <div className={styles.tooltipAddCap}>
                <button
                  disabled={isPlusButtonDisabled}
                  className={styles.plus}
                  onClick={() => setIsAddCapabilityModalShown(true)}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
                </button>

                <span class={styles.tooltiptextAddCap}>
                  <br />
                  Add Capability
                  <br />
                  <br />
                </span>
              </div>
            )}
            <div className={styles.tooltip}>
              <button className={styles.export} onClick={handleExportClicked}>
                <FontAwesomeIcon icon={faDownload} style={{ color: "green" }} />
              </button>
              <span class={styles.tooltiptext}>
                <br />
                Export Model
                <br />
                <br />
              </span>
            </div>
            {isAdmin && (
              <div className={styles.tooltip}>
                <button
                  className={styles.update}
                  onClick={handleUpdateModelClick}
                >
                  <FontAwesomeIcon icon={faUpload} style={{ color: "green" }} />
                </button>

                <span class={styles.tooltiptext} style={{ left: "6%" }}>
                  <br />
                  Update Model
                  <br />
                  <br />
                </span>
              </div>
            )}
          </div>
        )}

        <div className={styles.row}>
          <div className={styles.tree}>
            <ul className={styles.treeul}>
              {capabilityData.map((item) => (
                <CapabilityTreeNode
                  key={item.title}
                  data={item}
                  onNodeClick={setDrawerValues}
                  // td={toggleDrawer}
                  childcomponent={false}
                  drawerName={drawerName}
                  levelsForUser={levelsForUser}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>

      <AddCapabilityModal
        isAddCapabilityModalShown={isAddCapabilityModalShown}
        handleClose={handleClose}
        capabilityLevel={selectedCapabilityLevel}
        parentCapability={drawerName}
        documents={sampleDocumentData}
        formAddNewCapability={formAddNewCapability}
        selectedIndustry={selectedIndustry}
        selectedSector={selectedSector}
        selectedSubSector={selectedSubSector}
        handleSetFormAddNewCapability={handleSetFormAddNewCapability}
        capabilityTree={capabilityData}
      />
      <AddCapabilitySuccessModal
        isSuccessModalShown={isSuccessModalShown}
        capabilityLevel={selectedCapabilityLevel}
        parentCapability={drawerName}
        documents={sampleDocumentData}
        formAddNewCapability={formAddNewCapability}
      />
      <UploadCapabilityModal
        show={showUploadCapabilityModal}
        handleClose={setShowUploadCapabilityModal}
        handleFileUpload={handleFileUpload}
      />
    </>
  );
};

export default CapabilityTree;

import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "./CapabilityDocumentAccordion.module.css";

const CapabilityDocumentAccordion = ({ documentData }) => {
  return (
    <Accordion className={styles.capabilityAccordion} defaultActiveKey={0}>
      {documentData.map((data, index) => {
        return (
          <Accordion.Item key={data.name} eventKey={index}>
            <Accordion.Header className={styles.capabilityAccordionHeader}>
              {data.name} ({data.documents.length})
            </Accordion.Header>
            <Accordion.Body>
              <ul className={styles.capabilityDocumentList}>
                {data.documents.map((document) => (
                  <li className={styles.capabilityDocumentListItem} key={document.title}>
                    {document.title} <button className={styles.capabilityDocumentRemoveButton}><FontAwesomeIcon icon={faXmark} /></button>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default CapabilityDocumentAccordion;

import React, { useState } from "react";
import styles from "./CapabilityTreeNode.module.css";
import ExpandIconDown from "../../assets/images/ExpandIconDown.jpeg";

const CapabilityTreeNode = (props) => {
  const [isexpanded, setIsexpanded] = useState(
    parseInt(props.data.level.substring(1)) < 1
  );
  const [hasTail, setHasTail] = useState(false);

  const { title, children, description, level, label } = props.data;
  const data = props.data;

  const handleClick = (selectedchild, element) => {
    if (selectedchild.children) {
      setIsexpanded((prevState) => !prevState);

      //click on parent node it will not open all nodes
      if (parseInt(selectedchild.level.substring(1)) === 0) {
        setIsexpanded(true);
      }
    }
    if (selectedchild.children && selectedchild.children.children) {
      setHasTail(true);
    }

    //Selected Node Data Passing to Parent Component
    props.onNodeClick(selectedchild);

    // Get all the nodes in the capability tree
    // const nodes = document.querySelectorAll("#h");

    // // Add click event listeners to each node
    // nodes.forEach((node) => {
    //   node.addEventListener("click", () => {
    //     // Scroll to the clicked node
    //     node.scrollIntoView({ behavior: "smooth" });
    //   });
    // });
  };

  const hasChildren = children && children.length > 0;
  return (
    <>
      <li className={styles.treeli}>
        <div className={styles.tooltip}>
          <span className={styles.label}>{label}</span>

          <button
            id="h"
            className={`${styles.node} ${
              props.drawerName === data.title ? styles.clicked : ""
            } ${data.level.substring(1) < 1 ? styles.parentNode : ""}`}
            onClick={(e) => handleClick(data, e)}
          >
            {title}
          </button>

          {hasChildren &&
            // data.children &&
            parseInt(data.level.substring(1)) >= 1 &&
            parseInt(level.substring(1)) < props.levelsForUser &&
            isexpanded === false && (
              <img
                alt="ExpandIcon"
                src={ExpandIconDown}
                onClick={(e) => handleClick(data, e)}
                className={`${styles.iconArrowDown}`}
              />
            )}

          {hasChildren &&
            // data.children &&
            parseInt(data.level.substring(1)) >= 1 &&
            parseInt(level.substring(1)) < props.levelsForUser &&
            isexpanded === true && (
              <img
                alt="CollapseIcon"
                src={ExpandIconDown}
                onClick={(e) => handleClick(data, e)}
                className={`${styles.iconArrowUp}`}
              />
            )}
          {level.substring(1) > 0 && (
            <span class={styles.tooltiptext}>
              {description}
              <br />
              <br />
              <p className={styles.hoverText}> Click Capability to view more</p>
            </span>
          )}
        </div>

        {isexpanded &&
          hasChildren &&
          // children &&
          parseInt(level.substring(1)) < props.levelsForUser && (
            <ul className={styles.treeul}>
              {children.map((child) => (
                <CapabilityTreeNode
                  key={child.id}
                  data={child}
                  td={props.td}
                  onNodeClick={props.onNodeClick}
                  drawerName={props.drawerName}
                  isAdmin={props.isAdmin}
                  isAdminOrApproved={props.isAdminOrApproved}
                  levelsForUser={props.levelsForUser}
                />
              ))}
            </ul>
          )}
      </li>
    </>
  );
};

export default CapabilityTreeNode;
